/* eslint-disable no-plusplus */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/alt-text */
// import { formatPrice } from '@helper_currency';
import Typography from '@common_typography';
import useStyles from './style';

const OptionsItem = (props) => {
    const styles = useStyles();
    const {
        item, handleClick,
    } = props;
    const {
        name, type, position, small_image, /* price_range, */ breadcrumbs,
    } = item;
    let breadcrumbsText = '';
    if (breadcrumbs) {
        for (let i = 0; i < breadcrumbs.length; i++) {
            const element = breadcrumbs[i];
            breadcrumbsText += `${element.category_name} > `;
        }
    }
    return (
        <>
            {type === 'product' ? (
                <>
                    {position === 0
                        ? (
                            <Typography variant="label" size="18" letter="capitalize" type="bold" align="left">
                                <div style={{ color: '#2E7BBF' }}>
                                    PRODUCT
                                </div>
                            </Typography>

                        )
                        : null}
                    <div
                        className={styles.listContainer}
                        onClick={() => handleClick(item)}
                        onKeyPress={() => handleClick(item)}
                        role="button"
                        tabIndex="0"
                    >

                        <div className={styles.imageContainer}>
                            <img
                                className={styles.img}
                                src={small_image.url_original || '/assets/img/placeholder.png'}
                            />
                        </div>
                        <div>
                            <div className={styles.title}>
                                {name}
                            </div>
                            {/* <div className={styles.price}>
                                {formatPrice(price_range.minimum_price.final_price.value, price_range.minimum_price.final_price.currency)}
                            </div> */}
                        </div>
                    </div>
                </>
            ) : null}
            {type === 'category' ? (
                <>
                    {position === 0
                        ? (
                            <Typography variant="label" size="18" letter="capitalize" type="bold" align="left">
                                <div style={{ color: '#2E7BBF', margin: '20px 0 10px 0' }}>
                                    CATEGORIES
                                </div>
                            </Typography>
                        )
                        : null}
                    <div
                        className={styles.listContainerCategory}
                        onClick={() => handleClick(item, 'CATEGORY')}
                        onKeyPress={() => handleClick(item, 'CATEGORY')}
                        role="button"
                        tabIndex="0"
                    >
                        <div className={styles.breadcrumbs}>
                            {breadcrumbsText}
                        </div>
                        <div className={styles.titleCategory}>
                            {name}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default OptionsItem;
