import { debuging } from '@config';
import Alert from '@material-ui/lab/Alert';
import { getPopularCategory, getPopularTerms, setSearchTerm as mutationSearchTerm } from '@modules/theme/services/graphql/index';
import React from 'react';
import Router from 'next/router';
import { setResolver, getResolver } from '@helper_localstorage';
import CategorySkeleton from '@core_modules/commons/SearchModal/CategorySkeleton';

import PopularCategory from './PopularCategory';
import PopularTerms from './PopularTerms';

const CategoryWrapper = (props) => {
    const { value, t } = props;
    const [dataCat, setDataCat] = React.useState(null);
    const [dataTerm, setDataTerm] = React.useState(null);

    const [setSearchTerm] = mutationSearchTerm();
    const { loading, data, error } = getPopularTerms({
        variables: {
            pageSize: 5,
            currentPage: 1,
        },
    });
    const { loading: loadingCategory, data: dataCategory, error: errorCategory } = getPopularCategory({
        variables: {
            pageSize: 5,
            currentPage: 1,
        },
    });

    React.useMemo(() => {
        if (dataCat === null && !loadingCategory && dataCategory) {
            setDataCat(dataCategory.getPopularCategory.popularCategory);
        }
        if (dataTerm === null && !loading && data) {
            setDataTerm(data.popularTerms.termList);
        }
    });

    if (loading || loadingCategory) return <CategorySkeleton />;
    if (error || errorCategory) {
        return (
            <div>
                <Alert className="m-15" severity="error">
                    {debuging.originalError ? error.message.split(':')[1] : t('common:error:fetchError')}
                </Alert>
            </div>
        );
    }
    if (!data || !dataCategory) {
        return (
            <div>
                <Alert className="m-15" severity="error">
                    {t('common:error:notFound')}
                </Alert>
            </div>
        );
    }

    const handleClickCategory = async (cat) => {
        const link = `/${cat.url_key}`;
        const urlResolver = getResolver();
        urlResolver[link] = {
            type: 'CATEGORY',
            id: cat.id,
        };
        await setResolver(urlResolver);
        Router.push('/[...slug]', link);
    };

    const handleClickTerm = async (term) => {
        setSearchTerm({
            variables: {
                searchTerm: term.search_query,
            },
        }).then(() => {
            Router.push({
                pathname: '/catalogsearch/result',
                query: { q: term.search_query },
            });
        });
    };

    return (
        <>
            <PopularTerms
                data={dataTerm}
                handleClick={handleClickTerm}
                value={value}
                t={t}
            />
            <PopularCategory
                data={dataCat}
                handleClick={handleClickCategory}
                value={value}
                t={t}
            />

        </>
    );
};

export default CategoryWrapper;
