/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBack from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';
import Router from 'next/router';
import React from 'react';
import { setSearchTerm as mutationSearchTerm, getProduct } from '@modules/theme/services/graphql/index';
import TextField from '@material-ui/core/TextField';
import { getCategoryByName } from '@core_modules/theme/services/graphql';
import { useTranslation } from '@i18n';

import useStyles from './style';
import ItemsWrapper from './ItemsWrapper';
import SearchItems from './SearchItems';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

let globalTimeout = null;

const generateItemData = (product, category) => {
    const result = [];
    for (let index = 0; index < product.items.length; index++) {
        const element = product.items[index];
        const prod = {
            id: element.id,
            name: element.name,
            url_key: element.url_key,
            position: index,
            small_image: element.small_image,
            price_range: element.price_range,
            type: 'product',
        };
        result.push(prod);
    }
    for (let index = 0; index < category.length; index++) {
        const element = category[index];
        const cat = {
            id: element.id,
            name: element.name,
            url_key: element.url_path,
            breadcrumbs: element.breadcrumbs,
            position: index,
            type: 'category',
        };
        result.push(cat);
    }
    return result;
};

const SearchPage = (props) => {
    const styles = useStyles();
    const { t } = useTranslation(['common', 'home']);
    const [value, setValue] = React.useState('');
    const { open: openModal, setOpenModal, storeConfig } = props;
    const [setSearchTerm] = mutationSearchTerm();

    const handleCloseModal = () => {
        setValue('');
        setOpenModal(false);
    };

    const handleSearch = (ev) => {
        if (ev.key === 'Enter' && ev.target.value !== '') {
            if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.search) {
                window._swan.search({ searchKeyword: value });
            }
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        }
    };

    const searchByClick = () => {
        if (value !== '') {
            if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.search) {
                window._swan.search({ searchKeyword: value });
            }
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        }
    };

    const [item, setItem] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [close, setClose] = React.useState(false);
    const [search, setSearch] = React.useState('');

    const [
        actGetProduct,
        { loading, data, called },
    ] = getProduct(search);

    const [
        actGetCategory,
        { data: dCategory },
    ] = getCategoryByName(search);

    let itemData = [];
    if (data && dCategory && !open && !loading) {
        itemData = generateItemData(data.products, dCategory.categoryList);
    }

    const startAutocomplete = (e) => {
        setValue(e.target.value);
        const val = e.target.value;
        if (globalTimeout) {
            clearTimeout(globalTimeout);
        }

        globalTimeout = setTimeout(() => {
            setOpen(false);
            setClose(false);
            setSearch(val);
            if (!called) {
                actGetProduct();
                actGetCategory();
            }
        }, 300);
    };

    React.useEffect(() => {
        if (itemData.length > 0) {
            setItem(itemData);
            if (!close) {
                setOpen(true);
            }
        }
    }, [itemData.length]);

    return (
        <>
            <Dialog
                fullScreen
                open={openModal}
                TransitionComponent={Transition}
            >
                <div className={classNames(styles.container, 'container-search-bar')}>
                    <AppBar className={styles.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                onClick={handleCloseModal}
                                aria-label="close"
                            >
                                <ArrowBack className={styles.iconClose} />
                            </IconButton>

                            <div className="header-middle__search">
                                <TextField
                                    autoFocus
                                    id="standard-basic"
                                    label={t('home:searchProduct')}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onKeyPress={handleSearch}
                                    onChange={(e) => startAutocomplete(e)}
                                    InputProps={
                                        { classes: { root: styles.searchInputRoot } }
                                    }
                                />
                                <div
                                    role="button"
                                    tabIndex="0"
                                    className="search-icon"
                                    onClick={searchByClick}
                                    onKeyPress={searchByClick}
                                >
                                    <img alt="" src="/assets/img/header/search.png" />
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <ItemsWrapper value={value} t={t} />
                    <div className={value ? '' : 'hide'}>
                        <SearchItems items={item} />
                    </div>
                </div>
            </Dialog>
            <style jsx>
                {`
                .container-search-bar :global(.MuiSvgIcon-root) {
                    color: #2E7BBF;
                }
                .search-icon {
                    position: absolute;
                    width: 24px;
                    right: 4px;
                    border-radius: 99px;
                    background: #fff;
                    top: 21px;
                }
                .search-icon img {
                    width: 16px;
                }
                .header-middle__search {
                    display: flex;
                    flex-grow: 1;
                    align-items: center;
                    position: relative;
                    max-width: 500px;
                    top: 5px;
                }
                `}
            </style>
        </>
    );
};

export default SearchPage;
