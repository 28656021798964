import { makeStyles } from '@material-ui/core/styles';
import { CreatePadding } from '@theme_mixins';
import { WHITE } from '@theme_color';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
    },
    body: {
        ...CreatePadding(0, 44, 20, 44),
    },
    item: {
        margin: 0,
    },
    appBar: {
        position: 'relative',
        backgroundColor: WHITE,
        boxShadow: 'none',
        '& .MuiFormControl-marginNormal': {
            marginTop: 10,
            marginBottom: 30,
        },
    },
    searchInputRoot: {
        borderRadius: 99,
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #7696C7',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #7696C7',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #7696C7',
        },
    },
    icon: {
        maxHeight: 40,
        width: 'auto',
    },
    iconClose: {
        size: 30,
    },
    lastCat: {
        margin: 0,
        padding: 2,
        justifyContent: 'start',
        height: 'auto',
        marginBottom: '40px !important',
    },
    cat: {
        padding: 2,
        justifyContent: 'start',
        height: 'auto',
    },
    catNameWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    catIconWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default useStyles;
