/* eslint-disable no-plusplus */
import { setResolver, getResolver } from '@helper_localstorage';
import route from 'next/router';
import OptionAutocomplete from './view';
import useStyles from './style';

export default function ComboBox(props) {
    const {
        items,
    } = props;
    const styles = useStyles();

    const handleClick = async (item, type = 'PRODUCT') => {
        const { id, url_key } = item;
        const urlResolver = getResolver();
        urlResolver[`/${url_key}`] = {
            type,
            id,
        };
        await setResolver(urlResolver);
        route.push('/[...slug]', `/${url_key}`);
    };
    return (
        <div className={styles.body}>
            {items.map((item) => (
                <OptionAutocomplete item={item} handleClick={handleClick} />
            ))}
        </div>
    );
}
