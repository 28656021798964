import { makeStyles } from '@material-ui/core/styles';
import { CreatePadding } from '@theme_mixins';

const useStyles = makeStyles(() => ({
    body: {
        ...CreatePadding(0, 30, 20, 30),
    },
    listContainer: {
        height: '100%',
        padding: '10px 0',
        width: '100%',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        display: 'flex',
        alignItems: 'center',
    },
    topTitle: {
        display: 'block',
        width: '100%',
        height: '20px',
        paddingLeft: '13px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: '8px',
    },
    imageContainer: {
        float: 'left',
        marginRight: 20,
    },
    img: {
        width: '50px',
        height: '50px',
    },
    title: {
        paddingTop: 10,
        paddingBottom: 5,
        textTransform: 'uppercase',
        fontSize: 12,
    },
    price: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    listContainerCategory: {
        width: '100%',
        padding: 10,
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    topTitleCategory: {
        paddingLeft: 0,
    },
    breadcrumbs: {
        paddingBottom: 5,
        textTransform: 'uppercase',
        color: '#929292',
        fontSize: 10,
        fontStyle: 'italic',
    },
    titleCategory: {
        textTransform: 'uppercase',
        fontSize: 12,
    },
}));

export default useStyles;
