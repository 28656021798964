/* eslint-disable react/no-danger */
import React from 'react';
import Typography from '@common_typography';
import Button from '@common_button';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import useStyles from './style';

const PopularCategory = ({
    data, handleClick, value, t,
}) => {
    const styles = useStyles();
    return (
        <Slide
            direction="left"
            in={!value}
            timeout={200}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.body}>
                <Typography variant="label" size="18" letter="uppercase" type="bold" align="left">
                    <div style={{ color: '#2E7BBF' }}>
                        {t('common:search:choiceCategories')}
                    </div>
                </Typography>
                <div className={styles.item}>
                    {data
                        ? data.map((cat, index) => (
                            <div key={index} style={{ marginTop: 20 }}>
                                <Button
                                    fullWidth
                                    variant="text"
                                    align="left"
                                    onClick={() => handleClick(cat)}
                                    key={index}
                                >
                                    <div className="row" style={{ width: '100%' }}>
                                        <div className={classNames('col-xs-2', styles.catIconWrapper)}>
                                            <img src={cat.image_icon} alt={cat.name} className={styles.icon} />
                                        </div>
                                        <div className={classNames('col-xs-10', styles.catNameWrapper)}>
                                            <Typography variant="label" size="14" letter="capitalize" type="semiBold">
                                                {cat.name.toLowerCase()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        ))
                        : null}
                </div>
            </div>
        </Slide>
    );
};

export default PopularCategory;
